import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";
import Icon from "@mdi/react";
import { mdiFormatQuoteOpen } from "@mdi/js";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const query = graphql`
	query {
		strapiPageCeQueDAutresEnDisent {
			title
			slug
			body
			seo {
				metaDescription
				metaTitle
			}
			testimonials {
				body
			}
		}
	}
`;

const CeQueDAutresEnDisent = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageCeQueDAutresEnDisent;
	const breakpoints = useBreakpoint();

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div
					className={`container`}
					style={{ marginTop: `${breakpoints.largeUp ? `5rem` : ``}` }}
				>
					<div className={`grid grid-cols-1 lg:grid lg:grid-cols-2`}>
						{page.testimonials.map((testimonial, index) => (
							<div
								className={`testimonial ${
									breakpoints.largeUp
										? index % 2
											? `testimonial--bottom`
											: `testimonial--top`
										: ``
								}`}
							>
								<Icon
									path={mdiFormatQuoteOpen}
									size={7}
									className={`testimonial__icon`}
								/>
								<p className={`testimonial__body`}>{testimonial.body}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default CeQueDAutresEnDisent;
